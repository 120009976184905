require("@eonasdan/tempus-dominus");
import { Tooltip, Popover } from "bootstrap";

$(document).on("turbolinks:load", function () {
  $('[data-toggle="tooltip"]').each((_, el) => new Tooltip(el));
  $('[data-toggle="popover"]').each((_, el) => new Popover(el));

  $("#menu-mobile").click(function () {
    const body = document.body;
    const menu = document.getElementById("menu-items");
    const closeButton = document.getElementById("close");
    const openButton = document.getElementById("open");

    if (menu.style.display === "flex") {
      menu.style.display = "none";
      body.style.overflow = "initial";
      openButton.style.display = "block";
      closeButton.style.display = "none";
    } else {
      menu.style.display = "flex";
      body.style.overflow = "hidden";
      openButton.style.display = "none";
      closeButton.style.display = "block";
    }
  });
});

// Enable todolist text when checked
$(document).ready(function () {
  $("input[type=checkbox]").change(function () {
    if (this.checked) {
      $(this).next(".label-text").css("text-decoration-line", "line-through");
    } else {
      $(this).next(".label-text").css("text-decoration-line", "none");
    }
  });
});
