const getOrCreateImage = (value) => {
  const image = document.querySelector(`img[data-image="${value}"]`);
  if(image) {
    return image;
  }

  const newImage = document.createElement("img");
  newImage.setAttribute("width", "100");
  newImage.setAttribute("height", "100");
  newImage.setAttribute("data-image", value);

  return newImage;
};
const changeImageHandler = (event, imageInput) => {
  event.preventDefault();

  const [file] = imageInput.files
  const { imageInput: imageInputValue } = imageInput.dataset;

  if (file) {
    const image = getOrCreateImage(imageInputValue);
    image.src = URL.createObjectURL(file);

    const div = document.querySelector(`div[data-image="${imageInputValue}"]`);
    div.innerHTML = "";
    div.appendChild(image);
  }
};

$(document).on('turbolinks:load', function(){
  const imageInputs = document.querySelectorAll(
    "input[data-image-input]"
  )

  if (imageInputs) {
    Object.entries(imageInputs).forEach(([_, imageInput]) => {
      imageInput.addEventListener("change", (event) => {
        changeImageHandler(event, imageInput);
      });
    });
  }
});
