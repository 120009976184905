const selectTemplateHandler = (event, selectElement) => {
  event.preventDefault();

  const linkTo = document.querySelector("[data-link-to-apply-template]");

  const url = linkTo.getAttribute("href");

  linkTo.setAttribute("href", `${url}?template_id=${selectElement.value}`)
};

$(document).on('turbolinks:load', function(){
  const selectInput = document.querySelector(
    "select[data-select-template-id]"
  )

  if (selectInput) {
    selectInput.addEventListener("change", (event) => {
      selectTemplateHandler(event, selectInput);
    });
  }
});
