$(document).on('turbolinks:load', function(){
  if (document.querySelectorAll("[data-agreement-content-form]").length) {
    fetchTopics()

    $("#contents_topic_select").change(function (event) {
      const topicId = $(this).val();
      fetchChapters(topicId);
    });

    $("#contents_chapter_select").change(function (event) {
      const chapterId = $(this).val();
      fetchChapterItems(chapterId);
    });
  }
});

const fetchTopics = () => {
  fetch(`/contents/available_topics.json`)
    .then(data => data.json())
    .then(data => {
      $.each(data, function(_i, topic) {
        const currentTopicId = $("#current_contents_topic_id").val()
        let selected = '';

        if (currentTopicId && currentTopicId === topic.id) {
          selected = 'selected';
          fetchChapters(topic.id)
        }

        row = `<option value="${topic.id}" ${selected}>${topic.title}</option>`;
        $(row).appendTo("#contents_topic_select");
      });
    });
}

const fetchChapters = (topicId) => {
  fetch(`/contents/topics/${topicId}/available_chapters.json`)
    .then(data => data.json())
    .then(data => {
      cleanChapterItems();
      cleanChapters()

      $.each(data, function(_i, chapter) {
        const currentChapterId = $("#current_contents_chapter_id").val()
        let selected = '';

        if (currentChapterId && currentChapterId === chapter.id) {
          selected = 'selected';
          fetchChapterItems(chapter.id)
        }

        row = `<option value="${chapter.id}" ${selected}>${chapter.title}</option>`;
        $(row).appendTo("#contents_chapter_select");
      });
    });
}

const fetchChapterItems = (chapterId) => {
  fetch(`/contents/chapters/${chapterId}/available_chapter_items.json`)
    .then(data => data.json())
    .then(data => {
      cleanChapterItems();

      $.each(data, function(_i, item) {
        const currentChapterItemId = $("#current_contents_chapter_item_id").val();
        let selected = ''

        if (currentChapterItemId && item.identifier.includes(currentChapterItemId)) {
          selected = 'selected';
        }

        row = `<option value="${item.identifier}" ${selected}>${item.title}</option>`;
        $(row).appendTo("#content_chapter_item_id_input");
      });
    });
}

const cleanChapterItems = () => {
  $("#clinical_agreements_content_chapter_item option").remove();
  let row = "<option value=\"\"></option>";
  $(row).appendTo("#clinical_agreements_content_chapter_item");
}

const cleanChapters = () => {
  $("#contents_chapter_select option").remove();
  let row = "<option value=\"\"></option>";
  $(row).appendTo("#contents_chapter_select");
}
