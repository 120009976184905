const selectTypeHandler = (selectElement) => {
  const objectsToWorkWith = document.querySelectorAll("[data-selected-type]");

  objectsToWorkWith.forEach((element) => {
    const {selectedType} = element.dataset;

    if (selectElement.value === selectedType) {
      element.removeAttribute("hidden");
    } else {
      element.setAttribute("hidden", "");
      element.querySelectorAll("input, select")
        .forEach((input) => input.value = "");
    }
  });
};

$(document).on('turbolinks:load', function(){
  const selectInput = document.querySelectorAll(
    "select[data-select-type]"
  )

  selectInput.forEach((selectElement) => {
    selectTypeHandler(selectElement);

    selectElement.addEventListener("change", (event) => {
      event.preventDefault();

      selectTypeHandler(selectElement);
    });
  });
});
