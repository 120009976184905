$(document).on("turbolinks:load", function(){
  const bodyElement = document.querySelector('[data-intervention-note-body]');

  if (bodyElement == null) {
    return
  }

  const markAsSaved = () => {
    bodyElement.classList.add("is-valid")
    bodyElement.classList.remove("border-warning")
  }

  const markAsNotSaved = () => {
    bodyElement.classList.remove("is-valid")
    bodyElement.classList.add("border-warning")
  }

  const debounce = (f, timeout = 3000) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(
        () => f.apply(this, args),
        timeout
      );
    };
  };

  const saveInterventionNote = () => {
    const clinicalCaseId = document.querySelector("#clinical_case_id").value
    const sessionId = document.querySelector("#session_id").value
    const noteId = document.querySelector("#note_id").value
    const clinicalNoteBody = bodyElement.value

    const requestBody = {
      intervention_note: {
        body: clinicalNoteBody
      }
    }

    $.ajax({
      type: "PUT",
      url: `/clinical_cases/${clinicalCaseId}/intervention/sessions/${sessionId}/notes/${noteId}.json`,
      contentType: "application/json",
      data: JSON.stringify(requestBody),
      success: markAsSaved,
      error: (error) => console.log(JSON.stringify(error))
    });
  };

  bodyElement.addEventListener('keypress', markAsNotSaved);
  bodyElement.addEventListener('keypress', debounce(() => saveInterventionNote()));
});

