$(document).on('turbolinks:load', function(){
  let $inputEl = $('input[data-counter=true]')
  let dataLimit = $inputEl.data('maxLimit')
  const $textEl = $('#charsLimitValue')
  $textEl.text(dataLimit)

  $($inputEl).on('input', (e) => {
    $textEl.text(dataLimit - e.target.value.length)
  })
})
