$(document).on('turbolinks:load', function(){
    const clinicianInputEl = $('#clinicians-input')
    const clinicalCaseId = $("#session_clinical_case_id").find(":selected").val()

    if(Boolean(clinicalCaseId)) {
      populateCliniciansInput(clinicalCaseId)
    }

      $("#session_clinical_case_id").change(function (event) {
        clinicianInputEl.hide()
        populateCliniciansInput($(this).val())
        clinicianInputEl.show(500)
      });
  });

const sortByName = (a, b) => {
  if(a.name > b.name) return -1
  if(a.name < b.name) return 1
  return 0
}

const getClinicianId = (clinician) => clinician.value.split(" - ")[0]

  const populateCliniciansInput = (clinicalCaseId) => {
    const clinicalCaseCliniciansCheckboxListEl = $('#clinical-case-clinicians-checkbox-list')
    const otherCliniciansCheckboxListEl = $('#other-clinicians-checkbox-list')
    const allClinicians = $.map($('.clinicians'), (c) => ({value: c.value, checked: c.checked}));

    const cliniciansByCase = getClinicalCaseClinicians(clinicalCaseId)

    const clinicalCaseClinicians = allClinicians.filter((clinician) => cliniciansByCase
      .includes(getClinicianId(clinician)))
      .sort(sortByName)

    const othersClinicians = allClinicians.filter((clinician) => !cliniciansByCase.
      includes(getClinicianId(clinician)))
      .sort(sortByName)

    clinicalCaseCliniciansCheckboxListEl.html(buildCliniciansCheckBoxHtml(clinicalCaseClinicians))
    otherCliniciansCheckboxListEl.html(buildCliniciansCheckBoxHtml(othersClinicians))
  }

  const getClinicalCaseClinicians = (clinicalCaseId) => {
    const clinicians = $(`#clinicians_from_${clinicalCaseId}`);

    return clinicians[0].value.split(",");
  }

  const buildCliniciansCheckBoxHtml = (clinicians) => {
      const html = []
      eachSlice(clinicians, 30, (slice) => {
        html.push('<div class="col-4">')

        slice.forEach((c) => html.push(buildCheckBoxInput(c)))

        html.push("</div>")
      })

      return html.flat().join("")
  }

  const eachSlice = (array, size, callback) => {
    for (var i = 0, l = array.length; i < l; i += size){
      callback(array.slice(i, i + size));
    }
  };

  const buildCheckBoxInput = (clinician) => {
    const [id, name] = clinician.value.split(" - ")
      let input = $('<input>', {
            type: 'checkbox',
            name: 'session[clinician_ids][]',
            id: 'session_clinician_ids_',
            value: id
          })

    if (clinician.checked) {
      input.attr('checked', true)
    }

    return [input[0].outerHTML, ` ${name}`, '<br>']
  }
