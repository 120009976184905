$(document).on("turbolinks:load", function(){
  const clinicalNoteBodyElement = document.querySelector('[data-child-intervention-clinical-note-body]');

  if (clinicalNoteBodyElement == null) {
    return
  }

  const markAsSaved = () => {
    clinicalNoteBodyElement.classList.add("is-valid")
    clinicalNoteBodyElement.classList.remove("border-warning")
  }

  const markAsNotSaved = () => {
    clinicalNoteBodyElement.classList.remove("is-valid")
    clinicalNoteBodyElement.classList.add("border-warning")
  }

  const debounce = (f, timeout = 3000) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(
        () => f.apply(this, args),
        timeout
      );
    };
  };

  const saveClinicalNote = () => {
    const clinicalCaseId = document.querySelector("#clinical_case_id").value
    const clinicalNoteId = document.querySelector("#clinical_note_id").value
    const clinicalNoteBody = clinicalNoteBodyElement.value

    const requestBody = {
      child_intervention_clinical_note: {
        body: clinicalNoteBody
      }
    }

    $.ajax({
      type: "PUT",
      url: `/clinical_cases/${clinicalCaseId}/child_intervention_clinical_notes/${clinicalNoteId}.json`,
      contentType: "application/json",
      data: JSON.stringify(requestBody),
      success: markAsSaved,
      error: (error) => console.log(JSON.stringify(error))
    });
  };

  clinicalNoteBodyElement.addEventListener('keypress', markAsNotSaved);
  clinicalNoteBodyElement.addEventListener('keypress', debounce(() => saveClinicalNote()));
});

